 html,
 body,
 ion-content,
 ion-content::before,
 :root {

     &::-webkit-scrollbar {
         width: 0.625rem !important;
     }

     &::-webkit-scrollbar:horizontal {
         height: 0.3125rem !important;
     }

     &::-webkit-scrollbar-thumb {
         background-color: var(--ion-divider) !important;
         border-radius: 0.625rem !important;
     }

     &::-webkit-scrollbar-track {
         background-color: #ffffff !important;
         border-radius: 0.625rem !important;
     }

     &::-moz-scrollbar {
         width: 0.625rem !important;
     }

     &::-moz-scrollbar:horizontal {
         height: 0.3125rem !important;
     }

     &::-moz-scrollbar-thumb {
         background-color: var(--ion-divider);
         border-radius: 0.625rem !important;
     }

     &::-moz-scrollbar-track {
         background-color: #ffffff !important;
         border-radius: 0.625rem !important;
     }

 }